<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { isFuture, formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import { camelizeKeys } from 'humps';
import { useQuery, useMutation } from '@tanstack/vue-query';
import KalioMeeting from '@/components/shared/kalio-meeting.vue';
import BaseGroupMeetingControls from '@/components/group-meetings/base-controls.vue';
import {
  useGroupMeetingStore,
  useRolesStore,
  useUserStore,
  useBatchStore,
  useUIStore,
} from '@/stores';
import useActionCable from '@/hooks/actioncable.js';
import groupMeetingTimersApi from '@/api/group_meeting_timers.ts';

const props = defineProps({
  batchGroup: { type: Object, required: true },
  groupMeeting: { type: Object, required: true },
  groupMeetings: { type: Array, required: true },
  companies: { type: Array, required: true },
  callOwnerToken: { type: String, required: false, default: null },
});

const batchStore = useBatchStore();
batchStore.addBatchGroup(props.batchGroup);

const groupMeetingStore = useGroupMeetingStore();
const { setGroupMeeting, setGroupMeetings, setCompanies } = groupMeetingStore;
setGroupMeeting(props.groupMeeting);
setGroupMeetings(props.groupMeetings);
setCompanies(props.companies);

const timerDuration = ref(props.batchGroup.groupMeetingTimer);
const lastResetTimestamp = ref(null);
const timeLeftMessage = ref(null);
function updateTimeLeft() {
  if (!isFuture(props.groupMeeting.startDate)) {
    timeLeftMessage.value = null;

    return;
  }

  const timeLeft = formatDistanceToNowStrict(props.groupMeeting.startDate, { locale: es });
  timeLeftMessage.value = `Quedan ${timeLeft} para empezar la reunión.`;
}

let intervalId;
onMounted(() => {
  if (!isFuture(props.groupMeeting.startDate)) return;

  intervalId = setInterval(updateTimeLeft);
});

onUnmounted(() => {
  clearInterval(intervalId);
});

function updateTimerRefsFromEvent(event) {
  const { currentTimer } = camelizeKeys(event);
  timerDuration.value = currentTimer.timerSeconds;
  lastResetTimestamp.value = new Date(currentTimer.timerTimestamp);
}

useQuery(
  ['timer', props.groupMeeting.id],
  () => groupMeetingTimersApi.get(props.groupMeeting.id),
  {
    onSuccess: (data) => {
      lastResetTimestamp.value = new Date(data.timerTimestamp);
    },
  },
);

useActionCable({ channel: 'GroupMeetingChannel', id: props.groupMeeting.id }, {
  received(event) {
    if (event.kind !== 'timer_update') return;
    updateTimerRefsFromEvent(event);
  },
});

const { mutate: updateTimer } = useMutation(
  (newDuration) => groupMeetingTimersApi.create(
    props.groupMeeting.id,
    { groupMeetingTimer: newDuration },
  ),
  {
    onSuccess: () => {
      useUIStore().toast({
        message: 'Cronómetro actualizado',
        position: 'top',
        type: 'success',
      });
    },
  },
);

const { mutate: resetTimer } = useMutation(
  () => groupMeetingTimersApi.reset(props.groupMeeting.id),
  {
    onSuccess: () => {
      useUIStore().toast({
        message: 'Cronómetro reiniciado',
        position: 'top',
        type: 'success',
      });
    },
  },
);

const rolesStore = useRolesStore();
const userStore = useUserStore();
const isMuteAllowed = computed(() => rolesStore.isPartner);
const resetAllowed = computed(
  () => rolesStore.isPartner || userStore.currentUser.id === props.batchGroup.leaderId,
);
</script>

<template>
  <div class="flex w-full flex-col md:flex-row">
    <div class="flex h-128 grow flex-col px-12 py-8 md:h-auto">
      <kalio-alert
        v-if="timeLeftMessage"
        status="notice"
        :title="timeLeftMessage"
        :deletable="false"
      />
      <kalio-alert
        v-else-if="groupMeeting.finished"
        status="notice"
        title="Reunión finalizada"
        class="mb-4"
      >
        <span class="text-sm text-black">
          Esta reunión ya ha finalizado. Si tienes un coliseo ahora, puedes ir
          con <a
            href="/group_meetings/go"
            class="text-pv-light-blue hover:opacity-75"
          >
            este enlace
          </a>.
        </span>
      </kalio-alert>

      <div class="w-full grow">
        <div class="flex size-full flex-col">
          <base-group-meeting-controls
            :reset-allowed="resetAllowed"
            :is-mute-allowed="isMuteAllowed"
            :last-reset-timestamp="lastResetTimestamp"
            :timer-duration="timerDuration"
            @reset-timer="resetTimer"
            @update-timer="updateTimer"
          />
          <kalio-meeting
            :is-mute-allowed="isMuteAllowed"
            :daily-url="batchGroup.videocallUrl"
            :owner-token="callOwnerToken"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col md:-mb-24 md:-mt-4 md:w-96">
      <group-meeting-sidebar />
    </div>
  </div>
</template>
