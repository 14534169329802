<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useGroupMeetingStore } from '@/stores';
import KalioBackButton from '../shared/kalio-back-button.vue';
import KalioGroupMeetingsCompanyInfo from './company-info.vue';

const groupMeetingStore = useGroupMeetingStore();
const { groupMeeting, companies } = storeToRefs(groupMeetingStore);

const selectedCompany = ref(companies.value.length === 1 ? companies.value[0] : null);
function setCompany(company) {
  selectedCompany.value = company;
}
function resetCompany() {
  selectedCompany.value = null;
}
</script>

<template>
  <div class="flex size-full grow flex-col gap-3">
    <kalio-back-button
      v-if="!!selectedCompany && companies.length > 1"
      class="ml-4"
      @click="resetCompany"
    />
    <kalio-group-meetings-company-info
      v-if="selectedCompany"
      :company="selectedCompany"
      :group-meeting-start-date="groupMeeting.startDate"
    />
    <div
      v-else
      class="flex flex-col px-4"
    >
      <button
        v-for="company in companies"
        :key="company.id"
        class="flex w-full flex-row items-center space-x-4 rounded-lg border border-transparent p-4 transition duration-150 hover:border-blue-gray-700 hover:bg-pv-blue-700 focus:outline-none"
        @click="setCompany(company)"
      >
        <div class="size-9 shrink-0 overflow-hidden rounded-md">
          <img
            :src="company.isotypeUrl"
            class="size-full object-cover"
          >
        </div>
        <span class="grow text-left text-xl font-semibold">
          {{ company.name }}
        </span>
        <inline-svg
          :src="require('assets/images/icons/toggle-arrow.svg')"
          class="ml-auto h-auto w-4 shrink-0 rotate-90 fill-current text-pv-yellow transition"
        />
      </button>
    </div>
  </div>
</template>
