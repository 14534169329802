<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';

const props = defineProps({
  weeklyUpdate: { type: Object, default: null },
  programGoal: { type: [Object, null], required: true },
});

const variationPositive = computed(() => props.weeklyUpdate.computedKpiVariation >= 0);
</script>

<template>
  <div class="rounded-lg bg-pv-blue-900 p-3">
    <span
      v-if="!weeklyUpdate"
      class="text-pv-gray"
    >
      No hay actualización semanal
    </span>
    <div
      v-else
      class="flex flex-row items-stretch space-x-12"
    >
      <div class="flex flex-col space-y-2">
        <span
          v-if="programGoal"
          class="text-sm text-blue-gray-400"
        >
          {{ programGoal.kpiName }}
        </span>
        <span
          v-else
          class="text-xs font-light text-blue-gray-400"
        >
          No tiene métrica aún
        </span>
        <div class="flex flex-row items-end space-x-2">
          <span class="text-xl font-bold text-blue-gray-50">
            {{ $filters.abbreviateNumber(weeklyUpdate.kpi) }}
          </span>
          <span
            v-if="weeklyUpdate.previousWeeklyUpdate"
            class="font-light"
          >
            antes {{ $filters.abbreviateNumber(weeklyUpdate.previousWeeklyUpdate.kpi) }}
          </span>
        </div>
      </div>
      <div class="flex grow flex-col justify-between">
        <div class="flex flex-row items-center space-x-1">
          <inline-svg
            :src="require('assets/images/icons/calendar.svg')"
            class="mt-0.5 size-3 fill-current"
          />
          <span class="mt-1 shrink-0 self-end text-xs font-light text-blue-gray-400">
            {{ format(new Date(weeklyUpdate.startDate), 'yyyy-MM-dd') }}
          </span>
        </div>
        <div
          v-if="weeklyUpdate.previousWeeklyUpdate"
          class="mt-2 flex flex-row items-center space-x-3 self-start rounded-full px-2"
          :class="[
            variationPositive ? 'bg-emerald-300/30' : 'bg-red-300/30',
            variationPositive > 0 ? 'text-emerald-400' : 'text-red-400'
          ]"
        >
          <inline-svg
            :src="require(`assets/images/icons/arrow.svg`)"
            class="size-3 fill-current"
            :class="variationPositive > 0 ? 'rotate-90' : '-rotate-90'"
          />
          <span class="shrink-0">
            {{ Math.round(weeklyUpdate.computedKpiVariation * 10) / 10 }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
