<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { groupBy } from 'lodash';
import Tabs from '@/components/shared/tabs.vue';
import KalioComments from '@/components/shared/kalio-comments.vue';
import WeeklyUpdatesAccordion from '@/components/weekly-updates/weekly-updates-accordion.vue';
import KalioAccordionRow from '@/components/shared/kalio-accordion-row.vue';
import KalioCompanyProgramStats from '@/components/program/kalio-company-program-stats.vue';
import KalioFounderProfileCard from '@/components/shared/kalio-founder-profile-card.vue';
import { useRolesStore } from '@/stores';

const props = defineProps({
  company: { type: Object, required: true },
  isFromCurrentBatch: { type: Boolean, default: false },
  groupMeetings: { type: Array, default: () => [] },
});

const { t } = useI18n();

const rolesStore = useRolesStore();
const isPartner = computed(() => rolesStore.isPartner);

const dynamicCompany = ref({ ...props.company });

const orderedGroupMeetingPreparations = computed(() =>
  Object.values(groupBy(dynamicCompany.value.groupMeetingPreparations, 'groupMeetingId'))
    .reduce((arr, meetingPreparations) => {
      const newestPreparationForMeeting = meetingPreparations
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .pop();

      return arr.concat([newestPreparationForMeeting]);
    }, []),
);

const orderedCompanyUpdates = computed(() =>
  [...dynamicCompany.value.companyUpdates].sort((a, b) => parseISO(b.month) - parseISO(a.month)),
);

const statusSections = computed(() => {
  const sections = [
    'companyStatus.sections.weeklyReports', // Assuming i18n setup
    'companyStatus.sections.groupMeetings',
  ];
  if (isPartner.value) sections.push('companyStatus.sections.investorUpdates');

  return sections.map(section => t(section)); // Assuming i18n setup
});

function statusSection(index) {
  return statusSections.value[index];
}

function addCommentToList(comment) {
  dynamicCompany.value = {
    ...dynamicCompany.value,
    comments: [...dynamicCompany.value.comments, comment],
  };
}

const groupMeetingsById = computed(() => groupBy(props.groupMeetings, 'id'));

function formatGroupMeetingDate(date) {
  return format(date, 'd \'de\' MMMM yyyy', { locale: es });
}

function formatCompanyUpdateDate(date) {
  const parsedMonth = parseISO(date);

  return format(parsedMonth, 'MMMM yyyy', { locale: es });
}

function groupMeetingFromPreparation(preparation) {
  return groupMeetingsById.value[preparation.groupMeetingId][0];
}
</script>

<template>
  <div class="grid w-full grid-cols-1 rounded-lg bg-pv-blue-900 md:grid-cols-3">
    <div class="flex flex-col gap-4 px-10 py-5 md:col-span-2">
      <div class="flex flex-row items-center space-x-2">
        <h1 class="mb-0.5 text-4xl text-blue-gray-50">
          {{ dynamicCompany.name }}
        </h1>
        <a
          :href="dynamicCompany.webpage"
          target="_blank"
          class="text-light-blue-300"
        >
          <inline-svg
            :src="require('assets/images/icons/chain.svg')"
            class="size-5 stroke-current"
          />
        </a>
      </div>
      <div
        v-if="dynamicCompany.slogan"
        class="mb-2 text-base text-blue-gray-50"
      >
        {{ dynamicCompany.slogan }}
      </div>
      <div class="flex gap-2">
        <kalio-badge
          v-if="!!dynamicCompany.batchName"
          :text="dynamicCompany.batchName"
          color="pv-yellow"
          with-border
        />
        <kalio-badge
          v-if="!!dynamicCompany.inactiveSince"
          text="Inactiva"
          color="gray"
          with-border
        />
      </div>
      <div class="mb-1 mt-6 text-base font-medium text-blue-gray-50">
        {{ $t('general.founders') }}
      </div>
      <div class="flex flex-wrap gap-4">
        <kalio-founder-profile-card
          v-for="founder in dynamicCompany.users"
          :key="founder.id"
          :founder="founder"
          class="w-36 px-5 py-3.5"
        />
        <kalio-company-program-stats
          v-if="isFromCurrentBatch"
          :company="dynamicCompany"
          :weekly-updates="dynamicCompany.weeklyUpdates"
          :program-goal="dynamicCompany.programGoal"
        />
      </div>
      <div>
        <tabs
          :tabs="statusSections"
          variant="folder"
        >
          <template #[statusSection(0)]>
            <div class="w-full bg-blue-gray-700 px-5 py-6">
              <weekly-updates-accordion
                :weekly-updates="dynamicCompany.weeklyUpdates"
                :program-goal="dynamicCompany.programGoal"
                accordion-content-classes="md:flex-row md:space-x-6"
              />
            </div>
          </template>
          <template #[statusSection(1)]>
            <div class="w-full bg-blue-gray-700 px-4 py-6">
              <kalio-accordion-row
                v-for="preparation in orderedGroupMeetingPreparations"
                :key="preparation.id"
              >
                <template #title>
                  <span class="text-base font-semibold hover:opacity-75">
                    {{ formatGroupMeetingDate(groupMeetingFromPreparation(preparation).startDate) }}
                  </span>
                </template>
                <div class="pb-8">
                  <div
                    v-if="!!preparation.kpiComments"
                    class="space-y-1"
                  >
                    <div class="text-base text-blue-gray-50">
                      Sobre las métricas
                    </div>
                    <div class="text-xs font-light text-blue-gray-50">
                      {{ preparation.kpiComments }}
                    </div>
                  </div>
                </div>
              </kalio-accordion-row>
            </div>
          </template>
          <template
            v-if="isPartner"
            #[statusSection(2)]
          >
            <div class="flex flex-col">
              <div class="flex w-full flex-col bg-blue-gray-700 px-4 py-6">
                <div class="flex w-full justify-end">
                  <kalio-button
                    label="Nueva actualización"
                    href="/admin/tracking_pack_company_updates/new"
                    target="_blank"
                    size="small"
                  />
                </div>
                <a
                  v-for="update in orderedCompanyUpdates"
                  :key="update.id"
                  :href="`/admin/tracking_pack_company_updates/${update.id}`"
                  target="_blank"
                  class="text-base font-semibold transition-colors hover:text-pv-yellow"
                >
                  Actualización {{ formatCompanyUpdateDate(update.month) }}
                </a>
              </div>
            </div>
          </template>
        </tabs>
      </div>
    </div>
    <div class="border-blue-gray-800 px-3 py-4 md:col-start-3 md:row-span-2">
      <kalio-comments
        resource-type="Company"
        :resource="dynamicCompany"
        can-write
        @comment-created="addCommentToList"
      />
    </div>
  </div>
</template>
