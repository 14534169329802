import { formatCurrency } from './format_currency';
import { formatDate } from './format_date';
import { formatNumber } from './format_number';
import { formatPercentage } from './format_percentage';
function deaccentisize(value) { return value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); }
function capitalize(value) { return value.charAt(0).toUpperCase() + value.slice(1); }
function lowerDeaccentisize(value) { return deaccentisize(value.toLowerCase()); }
function toCurrency(value, options = {}) { if (typeof value !== 'number')
    return value; const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, ...options }); return formatter.format(value); }
function filterChain(value, filters) { if (!value)
    return null; let result = value; filters.forEach(filter => { result = filter(result); }); return result; }
function humanize(value, format = 'snake_case') { switch (format) {
    case 'snake_case': return value.replaceAll('_', ' ').toUpperCase();
    default: return value;
} }
function abbreviateNumber(value) { if (!Number(value) && value !== 0) {
    return value;
} const formatter = new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }); return formatter.format(value); }
function secondsToMMSS(seconds) { const minutes = Math.floor(seconds / 60); const remainingSeconds = seconds % 60; return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`; }
export { deaccentisize, capitalize, lowerDeaccentisize, toCurrency, filterChain, formatCurrency, formatDate, formatNumber, formatPercentage, humanize, abbreviateNumber, secondsToMMSS };
