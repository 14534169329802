<script>
export default {
  name: 'kalio-arrow-button',
  props: {
    text: { type: String, required: true },
    textClasses: { type: String, default: 'text-base text-blue-gray-100' },
    iconClasses: { type: String, default: 'w-3 h-3' },
  },
};
</script>

<template>
  <button class="flex w-full flex-row items-center justify-between py-3.5 pl-6 pr-4 hover:bg-pv-blue-800 focus:outline-none">
    <span :class="textClasses">
      {{ text }}
    </span>
    <inline-svg
      :src="require('assets/images/icons/toggle-arrow.svg')"
      class="rotate-90 fill-current text-pv-yellow"
      :class="iconClasses"
    />
  </button>
</template>
