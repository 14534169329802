<script>
import KalioWeeklyUpdatesChart from '../weekly-updates/chart.vue';

export default {
  name: 'kalio-company-program-stats',
  components: { KalioWeeklyUpdatesChart },
  props: {
    company: { type: Object, required: true },
    weeklyUpdates: { type: Array, default: () => ([]) },
    programGoal: { type: Object, default: () => ({}) },
  },
  computed: {
    totalWeeklyUpdates() {
      return this.company.weeklyUpdates.length;
    },
    weeklyUpdatesPoints() {
      return this.weeklyUpdates.map(update => ({ kpi: update.kpi, startDate: update.startDate }));
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col space-y-3">
    <div class="flex flex-row items-center space-x-3">
      <inline-svg
        :src="require('assets/images/icons/comment.svg')"
        class="size-5 fill-current"
      />
      <span class="text-lg">
        Estadísticas del programa
      </span>
    </div>
    <div class="flex flex-col space-y-3 xl:flex-row xl:space-x-5 xl:space-y-0">
      <div class="grow md:p-2">
        <kalio-weekly-updates-chart
          :weekly-updates-points="weeklyUpdatesPoints"
          :legend="(programGoa?.kpiName) || 'No tiene meta del programa'"
        />
      </div>
      <div class="flex shrink-0 flex-wrap gap-4 xl:flex-col">
        <div class="flex flex-col">
          <span class="text-sm text-pv-gray">
            Reporte subido
          </span>
          <span
            :class="company.weeklyUpdateSubmitted ? 'text-emerald-400' : 'text-red-400'"
            class="font-medium"
          >
            {{ company.weeklyUpdateSubmitted ? 'Sí' : 'No' }}
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-sm text-pv-gray">
            Han cumplido su meta
          </span>
          <span class="font-medium">
            {{ company.totalWeeksGoalAchieved }}/{{ totalWeeklyUpdates }} semanas
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-sm text-pv-gray">
            Office Hours última semana
          </span>
          <span class="font-medium">
            {{ company.totalOfficeHoursRequestedThisWeek }}
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-sm text-pv-gray">
            Office Hours totales
          </span>
          <span class="font-medium">
            {{ company.totalOfficeHoursRequested }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
