<script setup>
import { computed } from 'vue';
import KalioAccordionRow from '../shared/kalio-accordion-row.vue';
import BaseWeeklyUpdateCard from './base-card.vue';

const props = defineProps({
  weeklyUpdates: { type: Array, default: () => [] },
  programGoal: { type: Object, default: () => ({}) },
  accordionContentClasses: { type: String, default: '' },
});

const weeklyUpdatesSorted = computed(
  () => [...props.weeklyUpdates]
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate)),
);

</script>

<template>
  <div class="flex flex-col space-y-3">
    <kalio-accordion-row
      v-for="(update, index) in weeklyUpdatesSorted"
      :key="update.id"
      :is-open="index === 0"
    >
      <template #title>
        <span class="text-base font-semibold hover:opacity-75">
          Reporte semana {{ weeklyUpdates.length - index }}
        </span>
      </template>
      <div
        class="flex flex-col space-y-2 pb-2"
        :class="accordionContentClasses"
      >
        <base-weekly-update-card
          :weekly-update="update"
          :program-goal="programGoal"
        />
        <div>
          <div class="flex flex-row items-center space-x-2">
            <span class="text-lg">
              {{ update.goalAchieved ? 'Meta cumplida' : 'Meta no cumplida' }}
            </span>
            <div
              class="rounded-full border p-0.5"
              :class="update.goalAchieved > 0 ? 'text-emerald-400 border-emerald-400' : 'text-red-400 border-red-400'"
            >
              <inline-svg
                :src="require(`assets/images/icons/${update.goalAchieved ? 'check' : 'close'}.svg`)"
                class="size-2 fill-current"
              />
            </div>
          </div>
          <a
            :href="`/weekly_updates/${update.id}`"
            target="_blank"
            class="self-start text-sm text-blue-gray-200 underline"
          >
            Ver todo el reporte
          </a>
        </div>
      </div>
    </kalio-accordion-row>
  </div>
</template>
