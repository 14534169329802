<script>
import KalioModal from '@/components/shared/kalio-modal.vue';
import KalioArrowButton from '@/components/shared/kalio-arrow-button.vue';
import KalioBackButton from '@/components/shared/kalio-back-button.vue';

export default {
  name: 'demo-day-scheduling-meeting-modal',
  components: { KalioModal, KalioArrowButton, KalioBackButton },
  props: {
    demoDayCompany: { type: Object, required: true },
  },
  emits: ['close', 'event-scheduled'],
  data() {
    return {
      schedulingMotive: '',
    };
  },
  mounted() {
    const calendlyScript = document.createElement('script');
    calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    calendlyScript.setAttribute('async', true);
    document.head.appendChild(calendlyScript);
    window.addEventListener('message', this.handleCalendlyScheduledEvent);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleCalendlyScheduledEvent);
  },
  methods: {
    handleCalendlyScheduledEvent(e) {
      if (e.data.event?.startsWith('calendly')) {
        const eventType = e.data.event.split('.')[1];
        if (eventType === 'event_scheduled') {
          this.$emit('event-scheduled', this.schedulingMotive);
        }
      }
    },
  },
};
</script>

<template>
  <kalio-modal
    width-class="w-full sm:max-w-xl"
    @close="$emit('close')"
  >
    <div class="rounded-lg bg-pv-blue-700">
      <div class="flex flex-col space-y-1 p-3 sm:p-5">
        <div class="mr-12 flex flex-col space-y-4">
          <kalio-back-button
            v-if="schedulingMotive"
            class="self-start"
            @click="schedulingMotive = null"
          />
          <div class="flex flex-row items-center space-x-3">
            <img
              :src="demoDayCompany.company.isotypeUrl"
              class="size-12 rounded-lg"
            >
            <span class="text-xl font-medium">
              {{ demoDayCompany.company.name }}
            </span>
          </div>
        </div>
        <div class="px-4 py-8 sm:px-8">
          <div
            v-show="schedulingMotive === 'invest'"
            class="calendly-inline-widget h-112 w-full overflow-scroll sm:h-128"
            :data-url="demoDayCompany.investorsBookingLink"
          />
          <div
            v-show="schedulingMotive && schedulingMotive !== 'invest'"
            class="calendly-inline-widget h-112 w-full overflow-scroll sm:h-128"
            :data-url="demoDayCompany.bookingLink"
          />
          <div
            v-if="!schedulingMotive"
            class="flex w-full flex-col"
          >
            <span class="text-lg">
              {{ $t('demoDay.virtualRound.selectSchedulingMotive') }}
            </span>
            <kalio-arrow-button
              :text="$t('demoDay.virtualRound.schedulingMotives.invest')"
              class="mt-6 rounded-xl bg-pv-blue-900"
              @click="schedulingMotive = 'invest'"
            />
            <div class="my-8 flex w-full flex-row items-center space-x-8 px-2">
              <div class="h-px grow bg-blue-gray-500" />
              <span class="text-lg">
                {{ $t('demoDay.virtualRound.otherMotive') }}
              </span>
              <div class="h-px grow bg-blue-gray-500" />
            </div>
            <div class="flex flex-col divide-y divide-blue-gray-700 overflow-hidden rounded-xl bg-pv-blue-900">
              <kalio-arrow-button
                :text="$t('demoDay.virtualRound.schedulingMotives.client')"
                class="bg-pv-blue-900"
                @click="schedulingMotive = 'client'"
              />
              <kalio-arrow-button
                :text="$t('demoDay.virtualRound.schedulingMotives.partnership')"
                class="bg-pv-blue-900"
                @click="schedulingMotive = 'partnership'"
              />
              <kalio-arrow-button
                :text="$t('demoDay.virtualRound.schedulingMotives.other')"
                class="bg-pv-blue-900"
                @click="schedulingMotive = 'other'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </kalio-modal>
</template>
