<script setup>
import { computed } from 'vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { camelizeKeys } from 'humps';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import useActionCable from '@/hooks/actioncable.js';
import demoDayConnectedUsersApi from '@/demo_day_pack/api/connected_users';
import demoDayCompanyMeetingsApi from '@/demo_day_pack/api/demo_day_company_meetings';
import { useVirtualRoundStore } from '@/demo_day_pack/stores';
import { auctionChannelKey } from '@/demo_day_pack/utils/keys.js';

const virtualRoundStore = useVirtualRoundStore();
const { demoDay } = storeToRefs(virtualRoundStore);

const isDemoDayAvailable = computed(() => !!demoDay.value);

const { data: connectedUsers } = useQuery(
  ['connectedUsers', demoDay.value?.id],
  () => demoDayConnectedUsersApi
    .getAll(demoDay.value.id)
    .then(response => response.connectedUsers),
  { enabled: isDemoDayAvailable },
);

function isUserConnected(id) {
  return connectedUsers.value.some((user) => user.id === id);
}

const featuredUsers = computed(() => connectedUsers.value?.filter(user => user.featured) || []);
const commonUsers = computed(() => connectedUsers.value?.filter(user => !user.featured) || []);
const allUsers = computed(() => [...featuredUsers.value, ...commonUsers.value]);

const queryClient = useQueryClient();
useActionCable({ channel: 'DemoDayPack::DemoDayStreamingChannel', id: demoDay.value.id }, {
  received(event) {
    if (event.kind === 'user_connected' && !isUserConnected(event.user.id)) {
      const connectedUser = camelizeKeys(event.user);
      queryClient.setQueryData(['connectedUsers', demoDay.value?.id], old => [...old, connectedUser]);
    }
  },
});

const { data: demoDayCompanyMeetings } = useQuery(
  ['demoDayCompanyMeetings', demoDay.value?.id],
  () => demoDayCompanyMeetingsApi
    .getAll(demoDay.value.id)
    .then(response => response.demoDayCompanyMeetings),
  { enabled: isDemoDayAvailable },
);

useActionCable(auctionChannelKey, {
  received(event) {
    if (event.kind === 'meeting_scheduled') {
      queryClient.setQueryData(['demoDayCompanyMeetings', demoDay.value?.id], old => [...old, event.meeting_id]);
    }
  },
});

function getBadgeColorFromUser(user) {
  switch (user.investorType) {
  case 'institutional':
    return 'green';
  case 'founder':
    return 'pv-light-blue';
  case 'angel':
    return 'pv-yellow';
  default:
    return null;
  }
}

function getBadgeTextColorFromUser(user) {
  switch (user.investorType) {
  case 'institutional':
    return 'text-green-200';
  case 'founder':
    return 'text-blue-200';
  case 'angel':
    return 'text-yellow-200';
  default:
    return null;
  }
}

const { t } = useI18n();
function getBadgeTextFromUser(user) {
  switch (user.investorType) {
  case 'institutional':
    return user.investmentFirm;
  case 'founder':
    return 'Founder';
  case 'angel':
    return t('user.demoDayBadge.angel');
  default:
    return null;
  }
}
</script>

<template>
  <div class="flex flex-col gap-2 bg-pv-blue-900 p-2 lg:h-full lg:max-h-[575px] lg:w-64">
    <div class="flex flex-row items-center justify-center gap-4">
      <div
        v-if="connectedUsers"
        v-tooltip="$t('demoDay.streaming.attendees')"
        class="flex flex-row items-center gap-2"
      >
        <inline-svg
          :src="require('assets/images/icons/profiles.svg')"
          class="mb-px size-6 fill-current text-pv-gray"
        />
        <span class="font-medium">
          <span class="lg:hidden">
            {{ $t('demoDay.streaming.attendees') }}:
          </span>
          {{ connectedUsers.length }}
        </span>
      </div>

      <div
        v-if="demoDayCompanyMeetings"
        v-tooltip="$t('demoDay.streaming.scheduledMeetings')"
        class="flex flex-row items-center gap-2"
      >
        <inline-svg
          :src="require('assets/images/icons/outline/calendar.svg')"
          class="size-6 stroke-current"
        />
        <span class="font-medium">
          <span class="lg:hidden">
            {{ $t('demoDay.streaming.scheduledMeetings') }}:
          </span>
          {{ demoDayCompanyMeetings.length }}
        </span>
      </div>
    </div>
    <div
      id="overflowing-frame"
      class="grid w-full resize-y grid-cols-1 gap-4 overflow-y-scroll rounded-lg bg-pv-blue-800 p-2 md:grid-cols-3 lg:block lg:size-full lg:space-y-1"
      :class="(allUsers || []).length > 10 ? 'h-64' : 'h-32'"
    >
      <div
        v-for="user in allUsers"
        :key="user.id"
        class="flex w-full items-center gap-2"
      >
        <div class="size-10 shrink-0 overflow-hidden rounded-full">
          <img
            class="size-full object-cover"
            :src="user.avatarUrl"
          >
        </div>
        <div class="flex min-w-0 grow">
          <div class="flex w-full min-w-0 flex-col items-start">
            <p
              v-tooltip="user.formattedFullName"
              class="w-full truncate text-base font-normal"
            >
              {{ user.formattedFullName }}
            </p>
            <kalio-badge
              v-if="getBadgeTextFromUser(user)"
              class="mt-0.5 max-w-full capitalize"
              :color="getBadgeColorFromUser(user)"
              size="sm"
            >
              <p
                :class="getBadgeTextColorFromUser(user)"
                class="max-w-full truncate"
              >
                {{ getBadgeTextFromUser(user) }}
              </p>
            </kalio-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#overflowing-frame::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply w-2;
}

#overflowing-frame::-webkit-scrollbar-thumb {
  @apply bg-pv-gray bg-opacity-40 rounded-lg w-2 h-5;
}

#overflowing-frame::-webkit-resizer {
  border-width: 8px;
  border-style: solid;
  border-color: #131E2F #334155 #334155 #131E2F;
}
</style>
