<script setup>
import { ref, watch, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@tanstack/vue-query';
import { useUIStore } from '@/stores';
import useActionCable from '@/hooks/actioncable.js';
import { currentUserKey } from '@/utils/keys.js';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { useVirtualRoundStore, useDemoDayStreamigStore } from '../stores';
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import { auctionChannelKey, demoDayStreamingChannelKey } from '../utils/keys.js';
import liveDemoDayCompanyApi from '../api/live_demo_day_company.js';
import { useAuctionsQuery } from '../queries/auctions.js';
import DemoDayCompanyCard from './demo-day-company-card.vue';
import DemoDayCompaniesDashboard from './demo-day-companies-dashboard';
import DemoDayCompanyDashboard from './demo-day-company-dashboard.vue';
import DemoDayCompanyMobileAccordion from './demo-day-company-mobile-accordion.vue';
import DemoDayMobileCompanyTabs from './demo-day-mobile-company-tabs.vue';
import DemoDayAuctionMenu from './demo-day-auction-menu.vue';
import DemoDayCommunityInvestorApplicationModal from './demo-day-community-investor-application-modal.vue';
import DemoDayStreamingLiveData from './demo-day-streaming-live-data.vue';
import DemoDayPresentationsFinishedBannerContent from './demo-day-presentations-finished-banner-content.vue';

const props = defineProps({
  demoDay: { type: Object, required: true },
  demoDayCompanies: { type: Array, required: true },
  streamingUrl: { type: String, required: true },
  batchSeason: { type: String, required: true },
  investorAttendance: { type: Object, required: true },
  communityFormSections: { type: Object, required: true },
  investorApplication: { type: Object, default: null },
});

const virtualRoundStore = useVirtualRoundStore();
virtualRoundStore.setDemoDay(props.demoDay);
virtualRoundStore.setInvestorAttendance(props.investorAttendance);
const { demoDay: demoDayRef } = storeToRefs(virtualRoundStore);

const { virtualRoundTimer } = useVirtualRoundTimer();
const uiStore = useUIStore();

const { refetch: refetchAuctions, isFetching: isFetchingAuctions } = useAuctionsQuery(demoDayRef, {
  onSuccess: (data) => {
    virtualRoundStore.setAuctions(data);
  },
});

watch(virtualRoundTimer, () => {
  if (virtualRoundTimer.demoDayFinished) {
    uiStore.setBanner({
      isVisible: true,
      component: DemoDayPresentationsFinishedBannerContent,
    });
  }
});

const newOrderSymbol = Symbol();
const newMeetingSymbol = Symbol();
const { t } = useI18n();

useActionCable(auctionChannelKey, {
  received(event) {
    if (event.kind === 'auction_order_created') {
      uiStore.toast({
        id: newOrderSymbol,
        message: t('demoDay.virtualRound.someoneInvested'),
        type: 'success',
        duration: 12000,
      });
    } else if (event.kind === 'auction_updated') {
      refetchAuctions();
    } else if (event.kind === 'meeting_scheduled') {
      uiStore.toast({
        id: newMeetingSymbol,
        message: t('demoDay.virtualRound.someoneScheduledMeeting'),
        type: 'semi-dark',
        duration: 8000,
      });
    }
  },
});

const demoDayStreamingStore = useDemoDayStreamigStore();
const currentUser = inject(currentUserKey);
useActionCable({
  channel: demoDayStreamingChannelKey,
  room: currentUser.value.locale,
}, {
  received(event) {
    if (event.kind === 'live_demo_day_company_updated') {
      demoDayStreamingStore.setLiveDemoDayCompanyId(event.demo_day_company_id);
    }
  },
});

useActionCable(demoDayStreamingChannelKey, {
  received(event) {
    if (event.kind === 'demo_day_updated') {
      virtualRoundStore.refetchDemoDay();
    }
  },
});

useQuery(['liveDemoDayCompany'], () => liveDemoDayCompanyApi.get(currentUser.value.locale), {
  onSuccess: (id) => (demoDayStreamingStore.setLiveDemoDayCompanyId(id)),
});

const communityApplicationModalOpen = ref(false);

</script>

<template>
  <h1 class="md:text-xl">
    <a
      href="/demo_day"
      class="text-blue-gray-400 transition-colors hover:text-pv-yellow"
    >
      Home
    </a> / Streaming
  </h1>
  <span class="text-2xl font-medium text-pv-gray md:text-4xl">
    Demo Day {{ batchSeason }}
  </span>
  <div class="mt-6 flex flex-col overflow-hidden rounded-xl border border-blue-gray-700 bg-pv-blue-900 lg:flex-row">
    <div class="relative w-full overflow-hidden rounded-lg pt-[56.25%] lg:pt-[45%]">
      <iframe
        class="absolute inset-0 size-full"
        :src="streamingUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
    <div class="mt-2 lg:max-w-64">
      <demo-day-streaming-live-data />
    </div>
  </div>

  <div class="mt-8 flex flex-col divide-y divide-blue-gray-700 overflow-hidden rounded-xl border border-blue-gray-700 lg:hidden">
    <demo-day-company-mobile-accordion
      v-for="(demoDayCompany, index) in demoDayCompanies"
      :key="demoDayCompany.id"
      :demo-day-company="demoDayCompany"
      :should-open-accordion-on-rewatch-event="index === 0"
    >
      <div class="bg-pv-blue-900 p-4">
        <demo-day-mobile-company-tabs
          :demo-day-company="demoDayCompany"
          :should-scroll-into-view-on-rewatch-event="index === 0"
          @open-community-application-modal="communityApplicationModalOpen = true"
        />
      </div>
    </demo-day-company-mobile-accordion>
  </div>

  <div class="mt-8 hidden lg:block">
    <demo-day-companies-dashboard :demo-day-companies="demoDayCompanies">
      <template #default="{ demoDayCompany }">
        <div class="flex w-full flex-row divide-x divide-blue-gray-700 py-8">
          <demo-day-company-dashboard
            class="w-full pl-6 pr-4"
            :demo-day-company="demoDayCompany"
          >
            <template #card>
              <demo-day-company-card :demo-day-company="demoDayCompany" />
            </template>
          </demo-day-company-dashboard>
          <div class="flex w-112 flex-col items-center px-4">
            <kalio-spinner
              v-if="isFetchingAuctions"
              class="mt-12"
            />
            <demo-day-auction-menu
              v-else
              :demo-day-company="demoDayCompany"
              @open-community-application-modal="communityApplicationModalOpen = true"
            />
          </div>
        </div>
      </template>
    </demo-day-companies-dashboard>
  </div>
  <demo-day-community-investor-application-modal
    :show="communityApplicationModalOpen"

    :form-sections="communityFormSections"
    :investor-application="investorApplication"
    @close="communityApplicationModalOpen = false"
  />
</template>
