<script>
import { computed } from 'vue';
import KalioSpinner from './kalio-spinner.vue';

export default {
  name: 'kalio-badge',
  components: { KalioSpinner },
  props: {
    text: { type: String, default: null },
    color: {
      type: String,
      default: 'pv-light-blue',
      validator: (value) => [
        'pv-light-blue',
        'pv-yellow',
        'green',
        'indigo',
        'gray',
        'red',
      ].includes(value),
    },
    size: { type: String, default: 'xs' },
    withBorder: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false },
    deleteLoading: { type: Boolean, default: false },
    roundClass: { type: String, default: 'rounded-full' },
  },
  emits: ['delete'],
  setup(props) {
    const colorClasses = computed(() => {
      const classes = {
        'pv-light-blue': 'bg-pv-light-blue/10 text-pv-light-blue border-pv-light-blue',
        'pv-yellow': 'bg-pv-yellow/10 text-yellow-400 border-pv-yellow',
        'green': 'bg-green-400/10 text-green-400 border-green-400',
        'indigo': 'bg-indigo/10 text-indigo-400 border-indigo-400',
        'gray': 'bg-blue-gray-200/10 text-blue-gray-200 border-blue-gray-400',
        'red': 'bg-red-400/10 text-red-400 border-red-400',
      };

      return props.withBorder ? `${classes[props.color]} border` : classes[props.color];
    });

    const sizeClasses = computed(() => {
      const classes = {
        xs: 'px-1.5 py-0.5 text-xs gap-x-1',
        sm: 'px-2 py-0.5 text-sm gap-x-1',
      };

      return classes[props.size];
    });

    return {
      colorClasses,
      sizeClasses,
    };
  },
};
</script>

<template>
  <div
    class="flex shrink-0 flex-row items-center justify-center"
    :class="[colorClasses, sizeClasses, roundClass]"
  >
    <slot name="left" />
    <slot>
      <template v-if="deletable">
        <kalio-spinner
          v-if="deleteLoading"
          class="text-pv-light-blue"
          :size="3"
        />
        <button
          v-else
          :disabled="deleteLoading"
          @click="$emit('delete')"
        >
          <inline-svg
            :src="require('assets/images/icons/close.svg')"
            class="h-3 w-auto fill-current"
          />
        </button>
      </template>
      <span class="capitalize">
        {{ text }}
      </span>
    </slot>
    <slot name="right" />
  </div>
</template>
