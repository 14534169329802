<script setup>
import { ref, computed, inject, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { captureEvent } from '@/utils/analytics';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
import useActionCable from '@/hooks/actioncable.js';
import { currentUserKey } from '@/utils/keys.js';
import demoDayCompanyMeetingsApi from '../api/demo_day_company_meetings.js';
import useVirtualRoundTimer from '../composables/virtual_round_timer.js';
import { auctionChannelKey } from '../utils/keys.js';
import { useVirtualRoundStore, useDemoDayStreamigStore } from '../stores';
import { useAuctionOrderQuery } from '../queries/auction_orders.js';
import DemoDayAuctionInformation from './demo-day-auction-information.vue';
import DemoDayAuctionOrderInformation from './demo-day-auction-order-information.vue';
import DemoDayAuctionOrderModal from './auction-order-modal.vue';
import DemoDaySchedulingMeetingModal from './scheduling-meeting-modal.vue';

const emit = defineEmits(['open-community-application-modal']);
const props = defineProps({
  demoDayCompany: { type: Object, required: true },
});

const virtualRoundstore = useVirtualRoundStore();
const auction = computed(
  () => virtualRoundstore.getAuction(props.demoDayCompany.company),
);

const { investorAttendance } = storeToRefs(virtualRoundstore);
const { virtualRoundTimer } = useVirtualRoundTimer({ auction });

const {
  data: lastAuctionOrder,
  refetch: refetchAuctionOrder,
} = useAuctionOrderQuery(auction);

useActionCable(auctionChannelKey, {
  received(event) {
    if (event.kind === 'auction_order_created' && event.auction_id === auction.value.id) {
      refetchAuctionOrder();
    }
  },
});

const completeLegalInformationModalOpen = ref(false);
const auctionOrderModalOpen = ref(false);
const schedulingModalOpen = ref(false);

const demoDayStreamingStore = useDemoDayStreamigStore();
watch([completeLegalInformationModalOpen, auctionOrderModalOpen, schedulingModalOpen], (value) => {
  if (value.includes(true)) {
    demoDayStreamingStore.resetTimeSinceLastUserAction();
    demoDayStreamingStore.lockUserAction();
  } else {
    demoDayStreamingStore.unlockUserAction();
  }
});

function trackAnalytics() {
  captureEvent('round form opened', {
    company: props.demoDayCompany.company.name,
  });
}

function openAuctionOrderModal() {
  if (investorAttendance.value.rulesAccepted) {
    auctionOrderModalOpen.value = true;

    trackAnalytics();
  } else {
    emit('open-community-application-modal');
  }
}

function openSchedulingModal() {
  if (investorAttendance.value.rulesAccepted) {
    schedulingModalOpen.value = true;
  } else {
    emit('open-community-application-modal');
  }
}

function handleAddAuctionOrderSuccess() {
  auctionOrderModalOpen.value = false;
  completeLegalInformationModalOpen.value = true;
}

function handleEventScheduled(motive) {
  demoDayCompanyMeetingsApi.create(props.demoDayCompany.id, { motive });
}

const investmentLegalInformationPath = computed(
  () => `/demo_day/investments/${lastAuctionOrder.value.id}` +
            `/legal_informations/new?back_to=${window.location.pathname}` +
            `&demo_day_company_id=${props.demoDayCompany.id}`,
);

function goToInvestmentLegalInformation() {
  window.open(investmentLegalInformationPath.value);
}

const currentUser = inject(currentUserKey);
const isCompanyFounder = computed(() => currentUser.value.companyId === props.demoDayCompany.company.id);
</script>

<template>
  <div class="flex grow flex-col lg:w-72">
    <span class="hidden text-base font-medium md:block">
      {{ $t('demoDay.virtualRound.roundInfo') }}
    </span>
    <demo-day-auction-information :auction="auction">
      <template #timer>
        <div class="mt-3 flex flex-row justify-between gap-x-2">
          <span class="shrink-0 ">
            {{ virtualRoundTimer.auctionStarted
              ? $t('demoDay.virtualRound.auction.timeLeft')
              : $t('demoDay.virtualRound.auction.startsIn') }}
          </span>
          <div class="flex flex-row items-center space-x-2">
            <span class="text-right font-medium">
              {{ virtualRoundTimer.auctionStarted ?
                virtualRoundTimer.auctionTimeLeft :
                virtualRoundTimer.auctionStartsIn }}
            </span>
            <inline-svg
              :src="require('assets/images/icons/simple-clock.svg')"
              class="size-4 stroke-current"
            />
          </div>
        </div>
      </template>
    </demo-day-auction-information>
    <span
      v-if="virtualRoundTimer.auctionFinished"
      class="mt-2 self-center text-sm text-pv-gray"
    >
      {{ $t('demoDay.virtualRound.auction.finished') }}
    </span>
    <span
      v-else-if="!virtualRoundTimer.auctionStarted"
      class="mt-2 self-center text-sm text-pv-gray"
    >
      {{ $t('demoDay.virtualRound.auction.hasNotStarted') }}
    </span>
    <div
      v-if="!isCompanyFounder"
      class="mt-6 flex flex-col space-y-3"
    >
      <kalio-button
        :label="lastAuctionOrder
          ? $t('demoDay.virtualRound.auction.improveOffer')
          : $t('demoDay.virtualRound.invest')"
        :disabled="!virtualRoundTimer.auctionStarted || virtualRoundTimer.auctionFinished"
        @click="openAuctionOrderModal"
      />
      <kalio-button
        :label="$t('demoDay.virtualRound.scheduleMeeting')"
        variant="tertiary"
        :disabled="!virtualRoundTimer.auctionStarted || virtualRoundTimer.auctionFinished"
        @click="openSchedulingModal"
      />
    </div>
    <demo-day-auction-order-information
      v-if="lastAuctionOrder"
      :auction-order="lastAuctionOrder"
      :investment-legal-information-path="investmentLegalInformationPath"
    />
  </div>
  <demo-day-auction-order-modal
    v-if="auctionOrderModalOpen"
    :auction="auction"
    :last-auction-order="lastAuctionOrder"
    :company="demoDayCompany.company"
    @close="auctionOrderModalOpen = false"
    @success="handleAddAuctionOrderSuccess"
  />
  <demo-day-scheduling-meeting-modal
    v-if="schedulingModalOpen"
    :demo-day-company="demoDayCompany"
    @close="schedulingModalOpen = false"
    @event-scheduled="handleEventScheduled"
  />
  <kalio-feedback-modal
    v-if="completeLegalInformationModalOpen"
    width-class="w-full max-w-2xl"
    :title="$t('demoDay.virtualRound.orderCreatedSuccess.title')"
    :body="$t('demoDay.virtualRound.orderCreatedSuccess.body')"
    :accept="$t('demoDay.virtualRound.orderCreatedSuccess.accept')"
    :close="$t('demoDay.virtualRound.orderCreatedSuccess.close')"
    @accept="goToInvestmentLegalInformation"
    @close="completeLegalInformationModalOpen = false"
  />
</template>
